import { Alert } from "@mui/material";
import { ReactNode, createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import { userLangSelector } from "@/features/LangSwitcher/Slice/LangSlice";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/browser";

const ErrorContext = createContext<{ errorId: string }>({ errorId: '' });
const useErrorContext = () => useContext(ErrorContext);

function ErrorFallback() {

    const { t } = useTranslation();
    const { errorId } = useErrorContext();

    return (
        <Alert severity="error" sx={{mt: 1, mb: 1,}}>
            <div>
                {t("Something went wrong. Error code:")} {errorId}
            </div>
        </Alert>
    );
}

function ErrorsCatcher({ children, errorId }: { children: ReactNode | ReactNode[], errorId: string, }) {

    const lang = useSelector(userLangSelector);

    const logError = (error: Error, info: { componentStack: string }) => {
        // console.log('error is', error, 'info is', info);
    
        const message = `Error ID: ${errorId}
        ----------------------------------
        Error: ${error}
        ----------------------------------
        User lang: ${lang}
        ----------------------------------
        Component stack: ${info.componentStack}
        `;
    
        Sentry.captureException(error, {extra: {
            message,
        }});
    };

    return (
        <ErrorContext.Provider value={{ errorId }}>
            <ErrorBoundary
                onError={logError}
                fallback={<ErrorFallback />}
            >
                {children}
            </ErrorBoundary>
        </ErrorContext.Provider>
    );
}

export default ErrorsCatcher;