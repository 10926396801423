import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import configuredStore, {RootState} from "@/store/Store";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios, {AxiosError} from "axios";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import {DNAOrderDataInterface} from "@/features/DNAOrderContent/Interfaces/DNAOrderDataInterface";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";
import { notSelectedId } from "@/config/Other";

const restApiConfig = new RESTAPIConfig();

/**
 * @deprecated
 */
export const fetchOrderDataThunk = createAsyncThunk(
    "openedDNAOrder/fetchData",
    async ({orderNum}: {orderNum: string}) => {
        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getDNAOrderData({orderId: Number(orderNum)});

        const token = getAccessToken(configuredStore.getState());
        const userLang = configuredStore.getState().lang.userLang;

        try {
            const response = await axios.get<JsonResponseInterface<DNAOrderDataInterface>>(url, {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    [userLangHeaderName]: userLang,
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (response.data.data !== null) {
                configuredStore.dispatch(setOrder(response.data.data));
            }

        } catch (e: unknown) {
            const error = e as AxiosError;
            console.log(error);
        }
    }
);

function getInitialState() {
    return {
        loadingStatus: "idle" as LoadingStatus,
        order: null as DNAOrderDataInterface | null,
        fileIdsToOrderPaperCopy: [] as number[],
        physicalAddressIdToDeliverPaperCopies: notSelectedId,
    }
}

export const OpenedDNAOrderSlice = createSlice({
    name: "OpenedDNAOrderSlice",
    initialState: getInitialState(),
    reducers: {
        setOrder: (state, action: PayloadAction<DNAOrderDataInterface>) => {
            state.order = action.payload;
        },
        addFileIdToOrderPaperCopy: (state, action: PayloadAction<number>) => {
            state.fileIdsToOrderPaperCopy.push(action.payload);
        },
        removeFileIdFromOrderOfPaperCopy: (state, action: PayloadAction<number>) => {
            state.fileIdsToOrderPaperCopy = state.fileIdsToOrderPaperCopy.filter(id => id !== action.payload);
        },
        clearFileIdsForPaperCopy: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.fileIdsToOrderPaperCopy = initialState.fileIdsToOrderPaperCopy;
        },
        setPhysicalAddressId: (state, action: PayloadAction<number>) => {
            state.physicalAddressIdToDeliverPaperCopies = action.payload;
        },
        clearPhysicalAddressId: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.physicalAddressIdToDeliverPaperCopies = initialState.physicalAddressIdToDeliverPaperCopies;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(fetchOrderDataThunk.pending, (state, actiion) => {
            state.loadingStatus = LoadingStatus.loading;
        })
        .addCase(fetchOrderDataThunk.fulfilled, (state, actiion) => {
            state.loadingStatus = LoadingStatus.succeeded;
        })
        .addCase(fetchOrderDataThunk.rejected, (state, actiion) => {
            state.loadingStatus = LoadingStatus.failed;
        })
    }
});

export const {
    /** @deprecated */
    setOrder,
    addFileIdToOrderPaperCopy,
    removeFileIdFromOrderOfPaperCopy,
    clearFileIdsForPaperCopy,
    setPhysicalAddressId,
    clearPhysicalAddressId,
} = OpenedDNAOrderSlice.actions;
export const fileIdsToOrderPaperCopySelector = (state: RootState) => state.openedDNAOrder.fileIdsToOrderPaperCopy;
export const physicalAddressIdToDeliverCopiesSelector = (state: RootState) => state.openedDNAOrder.physicalAddressIdToDeliverPaperCopies;

export const OpenedDNAOrderReducer = OpenedDNAOrderSlice.reducer;