import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import {OrderDNATestFormConfig} from "@/features/OrderDNATestForm/Config/OrderDNATestFormConfig";
import {LoadingStatus} from "@/store/LoadingStatus";

export const notSelectedId = -1;

function getInitialState() {
    return {
        step: 0,

        step1: {
            orderId: {
                value: uuidv4(),
                valid: true,
                error: "",
            },
            partner: {
                value: notSelectedId,
                valid: false,
                error: "",
            },
            testType: {
                value: "",
                valid: false,
                error: "",
            },
            testTypeOther: {
                value: "",
                valid: false,
                error: "",
            },
            legalStatus: {
                value: "",
                valid: false,
                error: "",
            },
            attribute: {
                value: "",
                valid: false,
                error: "",
            },
            researchPeriod: {
                value: "",
                valid: false,
                error: "",
            },
        },
        step2: {
            participantId: "",

            fioOrNickname: {
                value: "",
                valid: false,
                error: "",
            },
            gender: {
                value: notSelectedId,
                valid: false,
                error: "",
            },
            birthday: {
                value: "",
                valid: true,
                error: "",
            },
            degreeOfKinship: {
                value: notSelectedId,
                valid: false,
                error: "",
            },
            degreeOfKinshipPreviousOrderNumber: {
                value: "",
                valid: false,
                error: "",
            },
            sampleType: {
                value: notSelectedId,
                valid: false,
                error: "",
            },
            sampleTypeOther: {
                value: "",
                valid: false,
                error: "",
            },
            documentType: {
                value: notSelectedId,
                valid: false,
                error: "",
            },
            documentNumber: {
                value: "",
                valid: false,
                error: "",
            },
            sampleCollectionDate: {
                value: "",
                valid: true,
                error: "",
            },
            whoCollectedSamples: {
                value: notSelectedId,
                valid: false,
                error: "",
            },
            commentForAllSamples: {
                value: "",
                valid: false,
                error: "",
            },

            isUserNewOrEdit: "new" as userNewOrEdit,

            testParticipants: [] as TestParticipantInterface[],
        },
        step3: {
            isThirdStepValid: true,

            //additional info
            pregnancyPeriod: {
                value: "",
                valid: false,
                error: "",
            },
            pregnancyPeculiarities: {
                value: "notChosen",
                valid: false,
                error: "",
            },
            describeKinshipTies: {
                value: "",
                valid: false,
                error: "",
            },
            anotherImportantInfo: {
                value: "",
                valid: false,
                error: "",
            },
            trackNumber: {
                value: "",
                valid: false,
                error: "",
            },

            //additional services
            copyOfReport: {
                value: false,
                valid: true,
                error: "",
            },
            urgentDispatchOfOpinionsByCourier: {
                value: false,
                valid: true,
                error: "",
            },
            translationOfConclusionIntoForeignLanguage: {
                value: false,
                valid: true,
                error: "",
            },
            returnOfSamples: {
                value: false,
                valid: true,
                error: "",
            },
            writtenConsultationWithSpecialistOnTest: {
                value: false,
                valid: true,
                error: "",
            },

            files: [
                {
                    id: uuidv4(),
                    file: null,
                    meaning: "",
                },
                {
                    id: uuidv4(),
                    file: null,
                    meaning: "",
                },
            ] as UploadingFileInterface[],
        },
        orderSendingStatus: LoadingStatus.idle,
    }
}

export const OrderDNATestFormSlice = createSlice({
    name: "OrderDNATestFormSlice",
    initialState: getInitialState(),
    reducers: {
        setStep: (state, action: PayloadAction<number>) => {
            state.step = action.payload;
        },

        setFormValue: (state, action) => {
            const {step, name, value} = action.payload;
            //@ts-ignore
            state[step][name].value = value;
        },
        setFormError: (state, action) => {
            const {step, name, error} = action.payload;
            //@ts-ignore
            state[step][name].error = error;
        },
        setFormValidation: (state, action) => {
            const {step, name, valid} = action.payload;
            //@ts-ignore
            state[step][name].valid = valid;
        },
        addTestParticipant: (state, action) => {
            const id = uuidv4();
            const fioOrNickname = state.step2.fioOrNickname.value;
            const gender = state.step2.gender.value;
            const birthday = state.step2.birthday.value;
            const degreeOfKinship = state.step2.degreeOfKinship.value;
            const degreeOfKinshipPreviousOrderNumber = state.step2.degreeOfKinshipPreviousOrderNumber.value;
            const sampleType = state.step2.sampleType.value;
            const sampleTypeOther = state.step2.sampleTypeOther.value;
            const sampleCollectionDate = state.step2.sampleCollectionDate.value;
            const whoCollectedSamples = state.step2.whoCollectedSamples.value;
            const documentType = state.step2.documentType.value;
            const documentNumber = state.step2.documentNumber.value;

            state.step2.testParticipants.push({
                id, fioOrNickname, gender, birthday, degreeOfKinship,
                degreeOfKinshipPreviousOrderNumber, sampleType,
                sampleTypeOther, sampleCollectionDate, whoCollectedSamples,
                documentType, documentNumber,
            });
        },
        clearParticipantForm: (state, action) => {
            const newInitialState = getInitialState();

            state.step2.fioOrNickname = newInitialState.step2.fioOrNickname;
            state.step2.gender = newInitialState.step2.gender;
            state.step2.birthday = newInitialState.step2.birthday;
            state.step2.degreeOfKinship = newInitialState.step2.degreeOfKinship;
            state.step2.degreeOfKinshipPreviousOrderNumber = newInitialState.step2.degreeOfKinshipPreviousOrderNumber;
            state.step2.sampleType = newInitialState.step2.sampleType;
            state.step2.sampleTypeOther = newInitialState.step2.sampleTypeOther;
            state.step2.sampleCollectionDate = newInitialState.step2.sampleCollectionDate;
            state.step2.whoCollectedSamples = newInitialState.step2.whoCollectedSamples;
            state.step2.documentType = newInitialState.step2.documentType;
            state.step2.documentNumber = newInitialState.step2.documentNumber;
        },
        deleteTestParticipant: (state, action) => {
            state.step2.testParticipants = state.step2.testParticipants.filter(participant => participant.id !== action.payload.id);
        },
        editTestParticipant: (state, action) => {
            const id = action.payload.id;
            const participant = state.step2.testParticipants.find(participant => participant.id === id) as TestParticipantInterface;
            state.step2.participantId = participant.id;
            state.step2.fioOrNickname.value = participant.fioOrNickname;
            state.step2.gender.value = participant.gender;
            state.step2.birthday.value = participant.birthday;
            state.step2.degreeOfKinship.value = participant.degreeOfKinship;
            state.step2.degreeOfKinshipPreviousOrderNumber.value = participant.degreeOfKinshipPreviousOrderNumber;
            state.step2.sampleType.value = participant.sampleType;
            state.step2.sampleTypeOther.value = participant.sampleTypeOther;
            state.step2.sampleCollectionDate.value = participant.sampleCollectionDate;
            state.step2.whoCollectedSamples.value = participant.whoCollectedSamples;
            state.step2.documentType.value = participant.documentType;
            state.step2.documentNumber.value = participant.documentNumber;
        },
        makeParticipantFormValid: (state, action) => {
            state.step2.fioOrNickname.valid = true;
            state.step2.birthday.valid = true;
            state.step2.degreeOfKinship.valid = true;
            state.step2.degreeOfKinshipPreviousOrderNumber.valid = state.step2.degreeOfKinshipPreviousOrderNumber.value !== "";
            state.step2.sampleType.valid = true;
            state.step2.sampleTypeOther.valid = state.step2.sampleTypeOther.value !== "";
            state.step2.whoCollectedSamples.valid = true;
        },
        setParticipantFormStatus: (state, action) => {
            state.step2.isUserNewOrEdit = action.payload.status;
        },
        saveEditedTestParticipant: (state, action) => {
            state.step2.testParticipants = state.step2.testParticipants.map(participant => {
                if (participant.id !== state.step2.participantId) {
                    return participant;
                }
                const editedParticipant: TestParticipantInterface = {
                    id: participant.id,
                    fioOrNickname: state.step2.fioOrNickname.value,
                    gender: state.step2.gender.value,
                    birthday: state.step2.birthday.value,
                    degreeOfKinship: state.step2.degreeOfKinship.value,
                    degreeOfKinshipPreviousOrderNumber: state.step2.degreeOfKinshipPreviousOrderNumber.value,
                    sampleType: state.step2.sampleType.value,
                    sampleTypeOther: state.step2.sampleTypeOther.value,
                    sampleCollectionDate: state.step2.sampleCollectionDate.value,
                    whoCollectedSamples: state.step2.whoCollectedSamples.value,
                    documentType: state.step2.documentType.value,
                    documentNumber: state.step2.documentNumber.value,
                };
                return editedParticipant;
            });
        },
        addDocumentRow: (state, action) => {
            if (state.step3.files.length === OrderDNATestFormConfig.maxDocumentsCount) {
                return;
            }
            const doc: UploadingFileInterface = {
                id: uuidv4(),
                file: null,
                meaning: "",
            };
            state.step3.files.push(doc);
        },
        deleteDocumentRow: (state, action) => {
            const id = action.payload.id;
            state.step3.files = state.step3.files.filter(file => file.id !== id);
        },
        setFileInfo: (state, action) => {
            const id = action.payload.id;
            const {name, size} = action.payload.file;
            state.step3.files = state.step3.files.map(fileInSlice => {
                if (fileInSlice.id !== id) {
                    return fileInSlice;
                }
                fileInSlice.file = {name, size};
                return fileInSlice;
            })
        },
        setFileMeaning: (state, action) => {
            const {id, value} = action.payload;
            state.step3.files = state.step3.files.map(fileInSlice => {
                if (fileInSlice.id !== id) {
                    return fileInSlice;
                }
                fileInSlice.meaning = value;
                return fileInSlice;
            })
        },
        clearAll: (state, action: PayloadAction<void>) => {
            const newInitialState = getInitialState();
            state.step1 = newInitialState.step1;
            state.step2 = newInitialState.step2;
            state.step3 = newInitialState.step3;
            state.orderSendingStatus = newInitialState.orderSendingStatus;
        },
        setSendingStatus: (state, action) => {
            state.orderSendingStatus = action.payload.loadingStatus;
        }
    },
});

export const {
    setStep,
    setFormValue,
    setFormError,
    setFormValidation,
    addTestParticipant,
    clearParticipantForm,
    deleteTestParticipant,
    editTestParticipant,
    makeParticipantFormValid,
    setParticipantFormStatus,
    saveEditedTestParticipant,
    addDocumentRow,
    deleteDocumentRow,
    setFileInfo,
    setFileMeaning,
    clearAll,
    setSendingStatus,
} = OrderDNATestFormSlice.actions;

//@ts-ignore
export const getSelectorForStep = state => state.orderDNATestForm.step;

//@ts-ignore
export const getSelectorForValue = (step, name) => state => state.orderDNATestForm[step][name].value;
//@ts-ignore
export const getSelectorForError = (step, name) => state => state.orderDNATestForm[step][name].error;
//@ts-ignore
export const getSelectorForValidation = (step, name) => state => state.orderDNATestForm[step][name].valid;
//@ts-ignore
export const getSelectorForParticipants = state => state.orderDNATestForm.step2.testParticipants;

//@ts-ignore
export const isFirstStepValidSelector = state => {
    const testTypeValid = state.orderDNATestForm.step1.testType.valid;
    const testTypeOtherValid = state.orderDNATestForm.step1.testTypeOther.valid;

    const testTypeValidation = (testTypeValid && state.orderDNATestForm.step1.testType.value !== "testTypeOther") || (testTypeValid && testTypeOtherValid);
    const legalStatusValid = state.orderDNATestForm.step1.legalStatus.valid;
    const attributeValid = state.orderDNATestForm.step1.attribute.valid;
    const researchPeriodValid = state.orderDNATestForm.step1.researchPeriod.valid;

    return testTypeValidation && legalStatusValid && attributeValid && researchPeriodValid;
};

//@ts-ignore
export const isSecondStepCompletedSelector = state => {
    return state.orderDNATestForm.step2.testParticipants.length > 0;
}

function isMeaningOfFilesValid(state: any) {
    let valid = true;
    state.orderDNATestForm.step3.files.forEach((file: UploadingFileInterface) => {
        if ("file" in file && file.file !== null && file.meaning === "") {
            valid = false;
        }
    });
    return valid;
}

//@ts-ignore
export const isThirdStepValidSelector = state => {
    const totalSizeMb = getChosenFilesTotalSize(state) / 1000000;
    const filesTotalSizeValid = totalSizeMb <= OrderDNATestFormConfig.maxFilesSizeMb;

    const chosenFilesCount = getChosenFilesCount(state);
    const filesCountValid = chosenFilesCount <= OrderDNATestFormConfig.maxDocumentsCount;

    const filesMeaningIsValid = isMeaningOfFilesValid(state);

    return filesTotalSizeValid && filesCountValid && filesMeaningIsValid;
}

//TODO здесь 39 и 60 получать более надежным способом, и в форме редактирования тоже самое
//@ts-ignore
export const isParticipantFormValidSelector = state => {
    const fioOrNicknameValid = state.orderDNATestForm.step2.fioOrNickname.valid;
    // const genderValid = state.orderDNATestForm.step2.gender.value !== notSelectedId;
    // const birthdayValid = state.orderDNATestForm.step2.birthday.valid;

    const degreeOfKinshipValid = state.orderDNATestForm.step2.degreeOfKinship.valid;
    const degreeOfKinshipPreviousOrderNumberValid = state.orderDNATestForm.step2.degreeOfKinshipPreviousOrderNumber.valid;
    const degreeOfKinshipValidation = (degreeOfKinshipValid && state.orderDNATestForm.step2.degreeOfKinship.value !== 39) || (degreeOfKinshipValid && degreeOfKinshipPreviousOrderNumberValid);

    const sampleTypeValid = state.orderDNATestForm.step2.sampleType.valid;
    const sampleTypeOtherValid = state.orderDNATestForm.step2.sampleTypeOther.valid;
    const sampleTypeValidation = (sampleTypeValid && state.orderDNATestForm.step2.sampleType.value !== 60) || (sampleTypeValid && sampleTypeOtherValid);

    // const sampleCollectionDateValid = state.orderDNATestForm.step2.sampleCollectionDate.value !== "";

    // const whoCollectedSamplesValid = state.orderDNATestForm.step2.whoCollectedSamples.valid;

    // const documentTypeValid = state.orderDNATestForm.step2.documentType.value !== notSelectedId;
    // const documentNumberValid = state.orderDNATestForm.step2.documentNumber.value.trim() !== "";

    return fioOrNicknameValid && 
            // genderValid &&
            // birthdayValid && 
            degreeOfKinshipValidation && 
            sampleTypeValidation
            // sampleCollectionDateValid &&
            // whoCollectedSamplesValid &&
            // documentTypeValid &&
            // documentNumberValid
            ;
}

//@ts-ignore
export const participantFormStateSelector = state => {
    return state.orderDNATestForm.step2.isUserNewOrEdit;
}

//@ts-ignore
export const filesSelector = state => {
    return state.orderDNATestForm.step3.files;
};
//@ts-ignore
export const fileByIdSelector = id => state => {
    const found = state.orderDNATestForm.step3.files.find((file: UploadingFileInterface) => {
        return file.id === id;
    });
    if (found === undefined) {
        throw new Error(`File with id: ${id} not found.`);
    }
    return found;
}

function getChosenFilesCount(state: any) {
    let count = 0;
    state.orderDNATestForm.step3.files.forEach((file: UploadingFileInterface) => {
        if ("file" in file && file.file !== null) {
            count++;
        }
    });
    return count;
}

//@ts-ignore
export const chosenFilesCountSelector = state => {
    return getChosenFilesCount(state);
};

function getChosenFilesTotalSize(state: any) {
    let size = 0;
    state.orderDNATestForm.step3.files.forEach((file: UploadingFileInterface) => {
        if ("file" in file && file.file !== null) {
            size += file.file.size;
        }
    });
    return size;
}

//@ts-ignore
export const chosenFilesSizeSelector = state => {
    return getChosenFilesTotalSize(state);
};

//@ts-ignore
export const orderDNASendingStatusSelector = state => {
    return state.orderDNATestForm.orderSendingStatus;
};

export const OrderDNATestFormReducer = OrderDNATestFormSlice.reducer;

export interface UploadingFileInterface {
    id: string,
    file: {name: string, size: number} | null,
    meaning: string,
}

export interface TestParticipantInterface {
    id: string,
    fioOrNickname: string,
    gender: number,
    birthday: string,
    degreeOfKinship: number,
    degreeOfKinshipPreviousOrderNumber: string,
    sampleType: number,
    sampleTypeOther: string,
    sampleCollectionDate: string,
    documentType: number,
    documentNumber: string,
    whoCollectedSamples: number,
}

export type userNewOrEdit = "new" | "edit";
