import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@/App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import reportWebVitals from './reportWebVitals';
import './i18n/i18n';
import * as Sentry from "@sentry/browser";


if (window.location.hostname === "lk.inlab-genetics.ru") {
  Sentry.init({
    dsn: "https://ae35b3ed0c5648dc8696d51a41d49800@lk.inlab-genetics.ru:38000/1",
  });
}

/*
if (window.location.hostname === "inlab.dev") {
  Sentry.init({
    dsn: "https://9ce845ead35c45c289c2c295642e01f5@inlab.dev:38000/1",
  });
}
*/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
